import React from 'react'
import { subDays, startOfDay } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBracketsCurly, faBug } from '@fortawesome/pro-light-svg-icons'

import {
  TopToolbar,
  Datagrid,
  Filter,
  List,
  TextField,
  DateTimeInput,
  useListContext,
  ListContextProvider,
  ExportButton,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'

import { Button } from '@material-ui/core'

const reportTypes = [
  { id: 'snippet-interactions', name: 'Snippet Interactions' },
  { id: 'snippet-answers', name: 'Snippet Answers' },
  { id: 'assessment-answers', name: 'Pre-Post Assessment Reponses' },
  { id: 'go-deeper', name: 'Go Deeper' },
  { id: 'device-usage', name: 'Device Usage' },
  { id: 'snippet-time', name: 'Snippet Time Spent' },
  { id: 'engagement-stats', name: 'Engagement Stats' },
  { id: 'reminder-emails', name: 'Reminder Emails' },
  { id: 'bounced-emails', name: 'Bounced Emails' },
]

const ReportGrid = (props) => {
  const listContext = useListContext()
  const { ids, filterValues } = listContext

  return (
    <>
      {filterValues.reportType === 'snippet-interactions' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="User_ID" label="User_ID" />
            <TextField source="Email" label="Email" />
            <TextField source="Organization_ID" label="Organization_ID" />
            <TextField source="Date" label="Date" />
            <TextField source="Time" label="Time" />
            <TextField source="Timestamp" label="Timestamp" />
            <TextField source="Snippet_ID" label="Snippet_ID" />
            <TextField source="Snippet_Title" label="Snippet_Title" />
            <TextField source="Program_ID" label="Program_ID" />
            <TextField source="Program_Name" label="Program_Name" />
            <TextField source="Action" label="Action" />
            <TextField source="Go_Deeper_Number" label="Go_Deeper_Number" />
            <TextField source="Option_ID" label="Option_ID" />
            <TextField source="Option_Score" label="Option_Score" />
          </Datagrid>
        </ListContextProvider>
      )}
      {filterValues.reportType === 'snippet-answers' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="User_ID" label="User_ID" />
            <TextField source="Email" label="Email" />
            <TextField source="Account_Name" label="Account_Name" />
            <TextField source="Date" label="Date" />
            <TextField source="Time" label="Time" />
            <TextField source="Timestamp" label="Timestamp" />
            <TextField source="Snippet_ID" label="Snippet_ID" />
            <TextField source="Snippet_Title" label="Snippet_Title" />
            <TextField source="Program_ID" label="Program_ID" />
            <TextField source="Program_Name" label="Program_Name" />
            <TextField source="Option_ID" label="Option_ID" />
            <TextField source="Option_Score" label="Option_Score" />
            <TextField source="Snippet_Difficulty" label="Snippet_Difficulty" />
            <TextField source="Combined_Score" label="Combined_Score" />
          </Datagrid>
        </ListContextProvider>
      )}
      {filterValues.reportType === 'assessment-answers' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="User_ID" label="User_ID" />
            <TextField source="Email" label="Email" />
            <TextField source="Account_Name" label="Account_Name" />
            <TextField source="Date" label="Date" />
            <TextField source="Time" label="Time" />
            <TextField source="Assessment_Type" label="Assessment_Type" />
            <TextField source="Question_Title" label="Question_Title" />
          </Datagrid>
        </ListContextProvider>
      )}
      {filterValues.reportType === 'go-deeper' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="Program_Name" label="Program Name" />
            <TextField source="Snippet_Title" label="Snippet Title" />
            <TextField source="Resource_Title" label="Resource Title" />
            <TextField source="Resource_Clicks" label="Resource Clicks" />
          </Datagrid>
        </ListContextProvider>
      )}
      {filterValues.reportType === 'device-usage' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="Label" label="Type" />
            <TextField source="Number" label="Number" />
            <TextField source="Percent" label="Percentage" />
          </Datagrid>
        </ListContextProvider>
      )}
      {filterValues.reportType === 'snippet-time' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="Snippet_Title" label="Snippet Title" />
            <TextField source="User_ID" label="User ID" />
            <TextField source="Time_Start" label="Started Snippet" />
            <TextField source="Time_End" label="Finished Snippet" />
            <TextField source="Time_Spent" label="Time Spent" />
          </Datagrid>
        </ListContextProvider>
      )}
      {filterValues.reportType === 'engagement-stats' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="Label" label="Item" />
            <TextField source="Number" label="Count" />
            <TextField source="Percent" label="Percent" />
            <TextField source="Option_Clicked" label="Option Clicked" />
            <TextField source="Average_Option_Clicked" label="Average Option Clicked" />
          </Datagrid>
        </ListContextProvider>
      )}
      {filterValues.reportType === 'reminder-emails' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="Email" label="Email" />
            <TextField source="Date" label="Date" />
            <TextField source="Opened" label="Opened" />
            <TextField source="Clicked" label="Clicked" />
            <TextField source="Program_Name" label="Program Name" />
          </Datagrid>
        </ListContextProvider>
      )}
      {filterValues.reportType === 'bounced-emails' && (
        <ListContextProvider value={{ ...listContext, ids }}>
          <Datagrid optimized {...props}>
            <TextField source="Date" label="Date" />
            <TextField source="Time" label="Time" />
            <TextField source="User_ID" label="User ID" />
            <TextField source="Email" label="Email" />
          </Datagrid>
        </ListContextProvider>
      )}
    </>
  )
}

const ReportActions = ({
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
}) => {
  const listContext = useListContext()
  const { setSort } = listContext
  const fromTimestamp = filterValues.dateFrom ? Date.parse(filterValues.dateFrom) : null
  const toTimestamp = filterValues.dateTo ? Date.parse(filterValues.dateTo) : null
  const programIdParam = filterValues.programId ? `&programId=${filterValues.programId}` : ''
  const accountIdParam = filterValues.accountId ? `&accountId=${filterValues.accountId}` : ''
  const exportButtonLink = `${process.env.REACT_APP_REPORTS_ENDPOINT}/${filterValues.reportType}?dateFrom=${fromTimestamp}&dateTo=${toTimestamp}&key=${process.env.REACT_APP_REPORTS_API_KEY}${programIdParam}${accountIdParam}`
  return (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <Button href={exportButtonLink} target={'_blank'} color="primary" size="small">
        <FontAwesomeIcon icon={faBracketsCurly} style={{ paddingRight: '0.5em' }} />
        Export JSON
      </Button>
      {/* Reset sort - if you switch reports while sorting */}
      <Button onClick={() => setSort()} color="primary" size="small">
        <FontAwesomeIcon icon={faBug} style={{ paddingRight: '0.5em' }} />
        Reset
      </Button>
    </TopToolbar>
  )
}

export const ReportList = (props) => (
  <List
    {...props}
    sort={{ field: 'Timestamp', order: 'DESC' }}
    actions={<ReportActions />}
    filterDefaultValues={{
      reportType: 'snippet-interactions',
      dateFrom: subDays(startOfDay(new Date()), 1).toISOString(),
    }}
    filters={
      <Filter>
        <AutocompleteInput source="reportType" choices={reportTypes} alwaysOn />
        <DateTimeInput label="Date From" source="dateFrom" alwaysOn />
        <DateTimeInput label="Date To" source="dateTo" />
        <ReferenceInput source="programId" reference="Program">
          <SelectInput optionText="slug" />
        </ReferenceInput>
        <ReferenceInput source="accountId" reference="Account">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Filter>
    }
  >
    <ReportGrid />
  </List>
)
