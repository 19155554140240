import React from 'react'
import slugify from 'slugify'
import jsonexport from 'jsonexport'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/pro-light-svg-icons'
import styles from './snippets.module.css'
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  TextField,
  RichTextField,
  TextInput,
  SimpleForm,
  SelectInput,
  DateField,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  TabbedForm,
  FormTab,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormDataConsumer,
  EditButton,
  BooleanInput,
  useListContext,
  TranslatableInputs,
  Toolbar,
  SaveButton,
  DeleteButton,
  Button,
  downloadCSV,
} from 'react-admin'
import RichTextInput from '../rich-text-input'
import { SUPPORTED_LOCALES } from '../../i18n'
import { TranslatableInput } from '../translatable-input'
import ArchiveOrRestoreButton from '../archive-or-restore-button'
import { OrderableArrayFormIterator } from '../orderable-array'

const SnippetGrid = (props) => {
  const {
    filterValues: { isArchived },
  } = useListContext()

  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="title.en" label="Title" />
      <RichTextField source="excerpt.en" label="Excerpt" />
      <TextField source="slug" />
      <DateField source="createdAt" showTime />
      <DateField source={isArchived ? 'deletedAt' : 'updatedAt'} showTime />
      <EditButton />
      <ArchiveOrRestoreButton />
    </Datagrid>
  )
}
export const SnippetList = (props) => (
  <List
    {...props}
    filters={
      <Filter>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="isArchived" alwaysOn />
      </Filter>
    }
  >
    <SnippetGrid />
  </List>
)

const CustomToolbar = (props) => {
  const { record = {} } = props
  return (
    <Toolbar {...props} className={styles.customToolbar}>
      <SaveButton />
      <Button
        onClick={() => {
          jsonexport(record, function (err, csv) {
            if (err) return console.error(err)
            downloadCSV(csv, record.slug)
          })
        }}
        label="Export CSV"
        startIcon={<FontAwesomeIcon icon={faFileExport} />}
      />
      <DeleteButton />
    </Toolbar>
  )
}

export const SnippetEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm redirect={false} toolbar={<CustomToolbar />}>
        <FormTab label="summary">
          <TextInput source="slug" fullWidth />
          <SelectInput
            source="difficulty"
            choices={[
              { id: 1, name: '1 - Low' },
              { id: 2, name: '2 - Medium' },
              { id: 3, name: '3 - Hard' },
            ]}
            fullWidth
          />
          <TextInput source="internalSummary" multiline fullWidth />
          <TranslatableInputs locales={SUPPORTED_LOCALES}>
            <TranslatableInput source="title" requiredLocales={['en']} fullWidth />
            <TextInput source="releaseEmailSubject" label="Email Subject" fullWidth />
            <RichTextInput source="excerpt" multiline fullWidth />
            <RichTextInput source="intro" />
            <RichTextInput source="introReferences" />
            <TranslatableInput source="question.title" requiredLocales={['en']} fullWidth />
          </TranslatableInputs>
        </FormTab>
        <FormTab label="Options">
          <ArrayInput source="question.options" fullWidth>
            <OrderableArrayFormIterator>
              <FormDataConsumer>
                {({ formData, getSource }) => (
                  <TranslatableInputs locales={SUPPORTED_LOCALES}>
                    <RichTextInput source={getSource('description')} label="Description" fullWidth />
                    <RichTextInput
                      source={getSource('consequences')}
                      label="Option Feedback - What Happened"
                      fullWidth
                    />
                  </TranslatableInputs>
                )}
              </FormDataConsumer>
              <NumberInput source="score" label="Score" />
              <ReferenceArrayInput label="Measurement Tags" reference="MeasurementTag" source="tagIds" fullWidth>
                <AutocompleteArrayInput optionText="title" />
              </ReferenceArrayInput>
              <SelectInput
                source="rariseStage"
                label="R-ARISE Stage"
                choices={[
                  { id: 'Resistant', name: 'R - Resistant' },
                  { id: 'Aware', name: 'A - Aware but unengaged' },
                  { id: 'Ready', name: 'R - Ready and motivated' },
                  { id: 'Informing', name: 'I - Informing oneself ' },
                  { id: 'Searching', name: 'S - Searching for opportunities' },
                  { id: 'Engaging', name: 'E - Engaging as an ally' },
                ]}
                allowEmpty={true}
              />
            </OrderableArrayFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Rationale">
          <TranslatableInputs locales={SUPPORTED_LOCALES}>
            <RichTextInput source="summary" label="At a glance / Summary" fullWidth />
            <RichTextInput source="question.rationale" label="Here’s Why - Rationale" />
            <RichTextInput source="descriptionReferences" label="Here’s Why - References" />
          </TranslatableInputs>
        </FormTab>
        <FormTab label="Resources">
          <ArrayInput source="resources" fullWidth>
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ formData, getSource }) => (
                  <TranslatableInputs locales={SUPPORTED_LOCALES}>
                    <TranslatableInput source={getSource('title')} label="Title" requiredLocales={['en']} fullWidth />
                    <TranslatableInput
                      source={getSource('description')}
                      label="Contribution/Owner"
                      requiredLocales={['en']}
                      fullWidth
                      multiline
                    />
                    <TranslatableInput source={getSource('url')} label="Url" requiredLocales={['en']} fullWidth />
                  </TranslatableInputs>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const SnippetCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TranslatableInputs locales={SUPPORTED_LOCALES}>
        <TranslatableInput source="title" requiredLocales={['en']} fullWidth />
      </TranslatableInputs>
      <FormDataConsumer>
        {({ formData: { title, slug } }) => (
          <TextInput source="slug" value={slug || slugify(title?.en ?? '', { lower: true })} fullWidth />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
)
