import Quill from 'quill'
import styles from './rich-text-input.module.css'

function $create(elName, classes) {
  return document.createElement(elName)
}

function $setAttr(el, key, value) {
  return el.setAttribute(key, value)
}

class insertTagButton {
  constructor(quill, optionsInput) {
    const options = optionsInput || {}
    // Add button to all quill toolbar instances
    const toolbarModule = quill.getModule('toolbar')
    if (!toolbarModule) {
      throw new Error('quill.insertTagButton requires the "toolbar" module to be included too')
    }
    this.registerDivModule()
    let toolbarEl = toolbarModule.container
    const buttonContainer = $create('span')
    $setAttr(buttonContainer, 'class', `ql-formats insert-tags-container ${styles.insertTagsContainer}`)
    const button = $create('button')
    button.innerHTML = options.buttonHTML || 'Insert Tag'
    button.title = options.buttonTitle || 'Insert Variable Tag'
    $setAttr(button, 'class', styles.openTagsButton)
    button.onclick = (e) => {
      e.preventDefault()
      this.toggleTags(e.target.nextSibling.firstChild)
    }
    const tagsComponent = this.createTagsComponent(options, quill)

    buttonContainer.appendChild(button)
    buttonContainer.appendChild(tagsComponent)
    toolbarEl.appendChild(buttonContainer)
    this.registerOutsideClickHandler()
  }

  createTagsComponent(options, quill) {
    const base = $create('div')
    $setAttr(base, 'class', `ql-formats ${styles.tagOptionsContainer}`)
    const picker = $create('div')
    $setAttr(picker, 'class', 'ql-insert-tag ql-picker')

    const optionsContainer = $create('div')
    optionsContainer.className = `ql-picker-options`

    const tagOptions = this.createTags(options, quill)
    tagOptions.forEach((tag) => {
      optionsContainer.appendChild(tag)
    })

    picker.appendChild(optionsContainer)
    base.appendChild(picker)
    return base
  }

  toggleTags(element) {
    element.classList.toggle('ql-expanded')
  }

  createTags(tags, quill) {
    return tags.map((tag) => {
      const button = $create('button')
      button.innerHTML = tag.label
      button.title = `Insert ${tag.label} into editor`
      button.className = `ql-insert-tag-button ${styles.tagOption}`
      button.onclick = (e) => {
        e.preventDefault()
        this.insertTag(tag.value, quill)
        this.toggleTags(e.target.closest('.ql-picker'))
      }
      return button
    })
  }

  insertTag(content, quill) {
    const selection = quill.getSelection(true)
    quill.insertText(selection.index, content)
  }

  registerDivModule() {
    // To allow divs to be inserted into html editor
    // obtained from issue: https://github.com/quilljs/quill/issues/2040
    var Block = Quill.import('blots/block')
    class Div extends Block {}
    Div.tagName = 'div'
    Div.blotName = 'div'
    Div.allowedChildren = Block.allowedChildren
    Div.allowedChildren.push(Block)
    Quill.register(Div)
  }

  registerOutsideClickHandler() {
    document.addEventListener('click', (e) => {
      const tagContainers = document.querySelectorAll('.insert-tags-container')
      let targetElement = e.target

      do {
        for (let i = 0; i < tagContainers.length; i++) {
          if (tagContainers[i] === targetElement) {
            tagContainers[i].querySelector('.ql-insert-tag').classList.add('ql-expanded')
            return
          }
          tagContainers[i].querySelector('.ql-insert-tag').classList.remove('ql-expanded')
        }
        targetElement = targetElement.parentNode
      } while (targetElement)

      // Close all of the tag containers
      tagContainers.forEach((tagContainer) => {
        tagContainer.querySelector('.ql-insert-tag').classList.remove('ql-expanded')
      })
    })
  }
}

window.insertTagButton = insertTagButton
export default insertTagButton
