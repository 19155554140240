import React, { useState } from 'react'
import zxcvbn from 'zxcvbn'
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  TopToolbar,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  EmailField,
  ExportButton,
  Filter,
  FormDataConsumer,
  FormTab,
  FormWithRedirect,
  List,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  Show,
  SimpleFormIterator,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  TextInput,
  useListContext,
} from 'react-admin'
import { Typography, Grid, Box, Toolbar, Button, LinearProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import SelectInputWithLinkToResource from '../select-input-with-link-to-resource'
import ArchiveOrRestoreButton from '../archive-or-restore-button'
import { SUPPORTED_LOCALES } from '../../i18n'
import styles from './users.module.css'

const ROLE_OPTIONS = [
  { id: 'ADMIN', name: 'ADMIN' },
  { id: 'LEARNER', name: 'LEARNER' },
]

const PRONOUN_OPTIONS = [
  {
    id: 'He/Him',
    name: 'He/Him',
  },
  {
    id: 'She/Her',
    name: 'She/Her',
  },
  {
    id: 'They/Them',
    name: 'They/Them',
  },
]

const FONT_SCALE_OPTIONS = [
  {
    id: '0.8',
    name: '0.8',
  },
  {
    id: '1.0',
    name: '1',
  },
  {
    id: '1.2',
    name: '1.2',
  },
  {
    id: '1.4',
    name: '1.4',
  },
  {
    id: '1.6',
    name: '1.6',
  },
  {
    id: '1.8',
    name: '1.8',
  },
  {
    id: '2.0',
    name: '2',
  },
]

const LOCALE_OPTIONS = SUPPORTED_LOCALES.map((locale) => ({ id: locale, name: locale }))

const measurePassword = (password, allValues) => {
  if (!password) return { score: 0 }
  return zxcvbn(password, [allValues.email])
}

const isSecurePassword = (value, allValues) => {
  if (!value) return []
  const { score } = measurePassword(value, allValues)
  if (score >= 3) return []
  return 'Too weak'
}

const matchesPassword = (value, allValues) => {
  if (value === allValues.password) return []
  return 'Must match password'
}

const UserActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <TopToolbar className={styles.listActionButtons}>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <Button href="/#/User/bulk-email" color="primary" size="small">
      <FontAwesomeIcon icon={faMailBulk} className={styles.uploadIcon} />
      Bulk Email
    </Button>
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </TopToolbar>
)

const PasswordStrengthMeter = () => {
  return (
    <FormDataConsumer>
      {({ formData: { password, email } }) => {
        const { score } = measurePassword(password, { email })
        return (
          <>
            <LinearProgress variant="determinate" value={Math.min((score / 3) * 100, 100)} />
          </>
        )
      }}
    </FormDataConsumer>
  )
}

const UserGrid = (props) => {
  const {
    filterValues: { isArchived },
  } = useListContext()
  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="organizationId" />
      <EmailField source="email" />
      <ChipField source="role" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <BooleanField source="subscribed" />
      <BooleanField source="activated" />
      <DateField source="createdAt" showTime />
      <DateField source={isArchived ? 'deletedAt' : 'updatedAt'} showTime />
      <EditButton />
      <ArchiveOrRestoreButton />
    </Datagrid>
  )
}
export const UserList = (props) => {
  return (
    <>
      <List
        {...props}
        actions={<UserActions />}
        filters={
          <Filter>
            <TextInput label="Search" source="q" alwaysOn />
            <BooleanInput label="Archived" source="isArchived" alwaysOn />
          </Filter>
        }
      >
        <UserGrid />
      </List>
    </>
  )
}

export const UserEdit = (props) => (
  <Edit {...props}>
    <TabbedForm redirect={false}>
      <FormTab label="User">
        <Box p="1em" fullWidth>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Typography variant="h6" gutterBottom>
                Identity
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextInput resource="User" source="firstName" fullWidth validate={required()} />
                </Grid>
                <Grid item xs={6}>
                  <TextInput resource="User" source="lastName" fullWidth validate={required()} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextInput resource="User" source="email" type="email" fullWidth validate={required()} />
                </Grid>
                <Grid item xs={6}>
                  <TextInput resource="User" source="organizationId" />
                </Grid>
              </Grid>
              <ChangePassword />
              <Box mt="1em" />
              <Typography variant="h6" gutterBottom>
                Address
              </Typography>
              <TextInput resource="User" source="profile.address" multiline fullWidth />
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput resource="User" source="profile.industry" fullWidth />
                </Box>
                <Box flex={2} ml="0.5em">
                  <TextInput resource="User" source="profile.company" fullWidth />
                </Box>
              </Box>
              <Box>
                <TextInput resource="User" source="profile.companyRole" fullWidth label="Company Role" />
              </Box>
              <Typography variant="h6" gutterBottom>
                Profile
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <SelectInput resource="User" source="profile.preferredPronouns" choices={PRONOUN_OPTIONS} fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <SelectInput resource="User" source="profile.fontScale" choices={FONT_SCALE_OPTIONS} fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <SelectInput resource="User" source="profile.locale" label="Locale" choices={LOCALE_OPTIONS} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                Status
              </Typography>
              <SelectInput source="role" choices={ROLE_OPTIONS} validate={required()} />
              <BooleanInput resource="User" source="subscribed" />
              <BooleanInput resource="User" source="activated" />
            </Grid>
          </Grid>
        </Box>
      </FormTab>
      <FormTab label="Package">
        <ReferenceInput allowEmpty emptyText="No Package" source="package.id" label="Package" reference="Package">
          <SelectInput optionText="title.en" />
        </ReferenceInput>
        <p>Updating a Learner's package will automatically enroll them into its first program.</p>
      </FormTab>
      <FormTab label="Active Programs">
        <p>Programs that the Learner currently has access to.</p>
        <ArrayInput source="programs">
          <SimpleFormIterator>
            <ReferenceInput source="programId" label="Program" reference="Program">
              <SelectInputWithLinkToResource optionText="name.en" resourceType="Program" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
)

const ChangePassword = () => {
  const [changing, setChanging] = useState(false)

  if (changing)
    return (
      <>
        <TextInput
          resource="User"
          source="password"
          type="password"
          validate={[required(), isSecurePassword]}
          fullWidth
        />
        <PasswordStrengthMeter />
        <TextInput
          resource="User"
          source="passwordConfirmation"
          type="password"
          validate={[required(), matchesPassword]}
          fullWidth
        />
      </>
    )
  else
    return (
      <Button color="primary" onClick={() => setChanging(true)}>
        Change Password
      </Button>
    )
}

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <ChipField source="role" />
      <BooleanField source="subscribed" />
      <BooleanField source="activated" />
    </SimpleShowLayout>
  </Show>
)

export const UserCreate = (props) => (
  <Create {...props}>
    <CreateForm />
  </Create>
)
export const CreateForm = (props) => (
  <FormWithRedirect
    {...props}
    render={(formProps) => (
      <form>
        <Box p="1em">
          <Box display="flex">
            <Box flex={2} mr="1em">
              <Typography variant="h6" gutterBottom>
                Identity
              </Typography>

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput resource="User" source="firstName" fullWidth validate={required()} />
                </Box>
                <Box flex={1} ml="0.5em">
                  <TextInput resource="User" source="lastName" fullWidth validate={required()} />
                </Box>
              </Box>

              <TextInput resource="User" source="email" type="email" fullWidth validate={required()} />
              <TextInput resource="User" source="password" type="password" validate={[isSecurePassword]} fullWidth />
              <PasswordStrengthMeter />
              <TextInput
                resource="User"
                source="passwordConfirmation"
                type="password"
                validate={[matchesPassword]}
                fullWidth
                helperText="If you leave the password blank, the user will be prompted to set a password when they click the
                confirmation link in their email."
              />

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                Address
              </Typography>

              <TextInput resource="User" source="profile.address" multiline fullWidth />
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput resource="User" source="profile.industry" fullWidth />
                </Box>
                <Box flex={2} ml="0.5em">
                  <TextInput resource="User" source="profile.company" fullWidth />
                </Box>
              </Box>
              <Box>
                <TextInput resource="User" source="profile.companyRole" fullWidth label="Company Role" />
              </Box>

              <Typography variant="h6" gutterBottom>
                Notification Language
              </Typography>
              <Box>
                <SelectInput resource="User" source="profile.locale" label="Locale" choices={LOCALE_OPTIONS} />
              </Box>
            </Box>

            <Box flex={1} ml="1em">
              <Typography variant="h6" gutterBottom>
                Status
              </Typography>

              <SelectInput source="role" choices={ROLE_OPTIONS} validate={required()} />
              <BooleanInput resource="User" source="subscribed" />
              <BooleanInput resource="User" source="activated" />
            </Box>
          </Box>
        </Box>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton saving={formProps.saving} handleSubmitWithRedirect={formProps.handleSubmitWithRedirect} />
          </Box>
        </Toolbar>
      </form>
    )}
  />
)

export const UserBulkEmail = () => <div>bulk email</div>
