import React, { useState } from 'react'
import { SimpleForm, Title } from 'react-admin'
import { TextField, Button, Card, CardContent, CardActions, Snackbar, Typography } from '@material-ui/core'
import { CSVReader } from 'react-papaparse'
import { Alert, AlertTitle } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { useMutation } from '@apollo/client'
import { client } from '../../buildGraphQLProvider'
import RichTextInput from '../rich-text-input'
import sendMutation from './send-bulk-email-mutation'
import CSVTemplate from './bulk-emails-template.csv'
import styles from './bulk-emails.module.css'

export const BulkEmail = () => {
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [addresses, setAddresses] = useState([])
  const [unsent, setUnsent] = useState([])
  const [errors, setErrors] = useState({
    email: false,
    subject: false,
    addresses: false,
  })
  const [submitError, setSubmitError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const [sendEmail] = useMutation(sendMutation, { client })

  const handleSubmit = (e) => {
    e.preventDefault()
    const submitErrors = {
      email: !email,
      subject: !subject,
      addresses: !addresses.length,
    }
    setErrors(submitErrors)
    setUnsent([])
    if (email && subject && addresses.length) sendBulkEmail()
  }

  const sendBulkEmail = async () => {
    // format papaparse output into a single array of emails
    const parsedAddresses = addresses
      .map((row) => row?.data.filter((cell) => cell !== '').map((cell) => cell.trim()))
      .flat()
    const variables = {
      email,
      subject,
      addresses: parsedAddresses,
    }

    try {
      const result = await sendEmail({ variables })
      setUnsent(result?.data?.sendBulkEmail || [])
      setSubmitSuccess(true)
    } catch (error) {
      setSubmitError(true)
    }
  }

  return (
    <SimpleForm onSubmit={handleSubmit} submitOnEnter={false} toolbar={<></>} redirect="/User">
      <Title title="Send Bulk Email" />
      <div className={`RaLayout-content-4 ${styles.bulkEmailsForm}`}>
        <div className="RaCreate-main-34 RaCreate-noActions-35">
          <Card>
            <CardContent>
              <TextField
                source="subject"
                error={errors.subject}
                variant="filled"
                label="Subject"
                fullWidth
                onChange={(e) => setSubject(e.target.value)}
              />
              <p />

              <Typography variant="h6">Template</Typography>
              {errors.email && (
                <Typography className="MuiFormHelperText-root Mui-error">
                  Please write an email template to send.
                </Typography>
              )}
              <RichTextInput
                source="emailBody"
                label="Template that gets sent to every user specified below"
                fullWidth
                onChange={(content) => setEmail(content)}
              />

              <Typography variant="h6">Email Recipients</Typography>
              <Typography className="MuiFormLabel-root MuiInputLabel-shrink" style={{ marginBottom: '12px' }}>
                Upload a CSV file containing email addresses on each new line to recieve the email. Email addresses must
                belong to an existing subscribed user to recieve the email.
              </Typography>
              <CSVReader
                onDrop={(data) => setAddresses(data)}
                onRemoveFile={() => setAddresses([])}
                addRemoveButton
                removeButtonColor="#659cef"
                config={{ skipEmptyLines: true }}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>
              {errors.addresses && (
                <Typography className="MuiFormHelperText-root Mui-error">
                  Please upload a CSV file of email addresses to recieve the email
                </Typography>
              )}
              <Typography gutterBottom>
                <a href={CSVTemplate} download="bulk-emails-template.csv">
                  Download CSV Template
                </a>
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" type="submit">
                <FontAwesomeIcon icon={faMailBulk} className={styles.emailIcon} />
                Send Emails
              </Button>
            </CardActions>
            {unsent.length > 0 && (
              <CardContent>
                <Typography className="MuiFormHelperText-root">
                  The following email addresses belong to either unregistered or unsubscribed users and cannot be sent:
                </Typography>
                {unsent.map((address) => (
                  <Typography className="MuiFormHelperText-root Mui-error">{address}</Typography>
                ))}
              </CardContent>
            )}
          </Card>
        </div>
      </div>
      {/* Error Snackbar */}
      <Snackbar
        open={submitError}
        autoHideDuration={3000}
        onClose={() => setSubmitError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={styles.alert}
      >
        <Alert severity="error" onClose={() => setSubmitError(false)}>
          <AlertTitle>Error</AlertTitle>
          There was an error sending the email.
        </Alert>
      </Snackbar>
      {/* Success Snackbar */}
      <Snackbar
        open={submitSuccess}
        autoHideDuration={3000}
        onClose={() => setSubmitSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={styles.alert}
      >
        <Alert severity="success" onClose={() => setSubmitSuccess(false)}>
          Bulk Emails have been successfully sent!
        </Alert>
      </Snackbar>
    </SimpleForm>
  )
}
