import React, { useCallback, useEffect, useMemo, useState } from 'react'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import { linkToRecord, useTranslate } from 'react-admin'

import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import MoveIcon from '@material-ui/icons/Reorder'

import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'

const DefaultAddItemForm = ({ choices, values, addItem, setFilter, optionText }) => {
  const [selected, setSelected] = useState(null)
  useEffect(() => {
    if (!selected) return
    addItem(selected.id)
    setSelected(null)
  }, [selected, setSelected, addItem])

  return (
    <Autocomplete
      options={choices.filter((choice) => !values.includes(choice.id))}
      onChange={(_e, value) => setSelected(value)}
      value={selected}
      openOnFocus
      fullWidth
      onInputChange={debounce((_e, newInputValue) => setFilter(newInputValue), 500)}
      renderInput={(inputParams) => <TextField variant="filled" {...inputParams} label="Add Item" margin="normal" />}
      getOptionLabel={(option) => get(option, optionText)}
    />
  )
}

export const OrderableSelect = (props) => {
  const {
    resource,
    choices,
    optionValue = 'id',
    optionText = 'title',
    input,
    setFilter,
    AddItemForm = DefaultAddItemForm,
  } = props

  const values = input.value || []
  const { onChange } = input

  const translate = useTranslate()
  const classes = useStyles(props)

  const getSuggestionFromValue = useCallback(
    (value) => choices.find((choice) => get(choice, optionValue) === value),
    [choices, optionValue]
  )

  const selectedItems = useMemo(
    () => values.map(getSuggestionFromValue).filter((value) => value != null),
    [getSuggestionFromValue, values]
  )

  const removeField = (index) => () => {
    let newValues = [...values]
    newValues.splice(index, 1)
    onChange(newValues)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const startIndex = result.source.index
    const endIndex = result.destination.index
    let newValues = [...values]
    const [removed] = newValues.splice(startIndex, 1)
    newValues.splice(endIndex, 0, removed)
    onChange(newValues)
  }

  return selectedItems ? (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <ul className={classes.root} ref={provided.innerRef}>
            {selectedItems.map((item, index) => (
              <Draggable draggableId={item.id} index={index} key={item.id}>
                {(itemProvided) => (
                  <li className={classes.line} ref={itemProvided.innerRef} {...itemProvided.draggableProps}>
                    <div {...itemProvided.dragHandleProps} className={classes.handle}>
                      <MoveIcon />
                    </div>
                    <div className={classes.title}>
                      <span>{get(item, optionText)}</span>
                    </div>
                    <Button component={Link} to={linkToRecord(`/${resource}`, item.id)} target="_blank">
                      <EditIcon />
                    </Button>
                    <span className={classes.action}>
                      <Button size="small" onClick={removeField(index)}>
                        <CloseIcon className={classes.leftIcon} />
                        {translate('ra.action.remove')}
                      </Button>
                    </span>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <li className={classes.form}>
              <AddItemForm
                values={values}
                choices={choices}
                addItem={(id) => onChange([...values, id])}
                optionText={optionText}
                setFilter={setFilter}
              />
            </li>
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  ) : null
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      '& > li:last-child': {
        borderBottom: 'none',
      },
    },
    line: {
      display: 'flex',
      listStyleType: 'none',
      borderBottom: `solid 1px ${theme.palette.divider}`,
      padding: '10px 0',
      alignItems: 'flex-start',
      [theme.breakpoints.down('xs')]: { display: 'block' },
    },
    handle: {
      paddingRight: '10px',
    },
    title: { flex: 2 },
    leftIcon: {
      marginRight: theme.spacing(),
    },
    form: {
      display: 'flex',
      listStyleType: 'none',
      padding: '10px 0',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: { display: 'block' },
    },
  }),
  { name: 'OrderableSelect' }
)
