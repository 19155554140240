import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  TextField,
  TextInput,
  SimpleForm,
  BooleanInput,
  SelectInput,
  DateField,
  EditButton,
  useListContext,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  NumberInput,
  RadioButtonGroupInput,
  TranslatableInputs,
} from 'react-admin'
import ArchiveOrRestoreButton from '../archive-or-restore-button'
import FormSafeTypography from '../utils/form-safe-typography'
import { SUPPORTED_LOCALES } from '../../i18n'
import styles from './assessment-questions.module.css'
import { TranslatableInput } from '../translatable-input'

const Tags = ({ record = {} }) => {
  const tags = record.tags
  if (typeof tags === 'undefined' || tags === null || tags.length === 0) {
    return <div />
  } else {
    return tags.join(', ')
  }
}

const AssessmentQuestionGrid = (props) => {
  const {
    filterValues: { isArchived },
  } = useListContext()
  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="title.en" label="Title" />
      <Tags source="tags" />
      <DateField source="createdAt" showTime />
      <DateField source={isArchived ? 'deletedAt' : 'updatedAt'} showTime />
      <EditButton />
      <ArchiveOrRestoreButton />
    </Datagrid>
  )
}

export const AssessmentQuestionList = (props) => (
  <List
    {...props}
    filters={
      <Filter>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="isArchived" alwaysOn />
      </Filter>
    }
  >
    <AssessmentQuestionGrid />
  </List>
)

export const AssessmentQuestionEdit = (props) => {
  return (
    <Edit {...props}>
      <AssessmentQuestionForm />
    </Edit>
  )
}

export const AssessmentQuestionCreate = (props) => (
  <Create {...props}>
    <AssessmentQuestionForm />
  </Create>
)

const AssessmentQuestionForm = (props) => (
  <SimpleForm {...props}>
    <TranslatableInputs locales={SUPPORTED_LOCALES}>
      <TranslatableInput source="title" label="Question Title" requiredLocales={['en']} fullWidth />
    </TranslatableInputs>
    <RadioButtonGroupInput
      source="type"
      label="Question Input Type"
      validate={[required()]}
      fullWidth
      defaultValue="scale"
      choices={[
        { id: 'scale', name: 'Scale' },
        { id: 'shortTextInput', name: 'Short Text Input' },
        { id: 'longTextInput', name: 'Long Text Input' },
      ]}
    />
    <FormSafeTypography>Scale Configuration</FormSafeTypography>
    <NumberInput
      source="scaleStart"
      label="Start"
      validate={[required()]}
      defaultValue={1}
      formClassName={styles.formRow}
    />
    <NumberInput
      source="scaleEnd"
      label="End"
      validate={[required()]}
      defaultValue={5}
      formClassName={styles.formRow}
    />
    <NumberInput
      source="scaleStep"
      label="Step"
      validate={[required()]}
      defaultValue={1}
      formClassName={styles.formRow}
    />
    <BooleanInput source="reverseScoring" label="Reverse Scoring" defaultValue={false} fullWidth />
    <SelectInput
      source="rariseStage"
      label="R-ARISE Stage"
      choices={[
        { id: 'Resistant', name: 'R - Resistant' },
        { id: 'Aware', name: 'A - Aware but unengaged' },
        { id: 'Ready', name: 'R - Ready and motivated' },
        { id: 'Informing', name: 'I - Informing oneself ' },
        { id: 'Searching', name: 'S - Searching for opportunities' },
        { id: 'Engaging', name: 'E - Engaging as an ally' },
      ]}
      allowEmpty={true}
      fullWidth
    />
    <ReferenceArrayInput label="Measurement Tags" reference="MeasurementTag" source="tagIds" fullWidth>
      <AutocompleteArrayInput optionText="title" />
    </ReferenceArrayInput>
  </SimpleForm>
)
