import React from 'react'
import { useUpdate, useRefresh, useNotify, DeleteButton, Button } from 'react-admin'

const ArchiveOrRestoreButton = (props) => {
  if (!props.record) return <span />
  if (props.record.deletedAt) {
    return <RestoreButton {...props} />
  }
  return <DeleteButton {...props} />
}

const RestoreButton = ({ resource, record, ...rest }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const [update, { loading }] = useUpdate(resource, record.id, { id: record.id, deletedAt: null }, record, {
    undoable: true,
    onSuccess: () => {
      refresh()
      notify('resources.unArchivedSuccess', 'info', {}, true)
    },
    onFailure: (error) => notify('resources.unArchivedFailed', 'warning'),
  })

  return <Button label="Restore" disabled={loading} onClick={update} />
}

export default ArchiveOrRestoreButton
