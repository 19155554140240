import React from 'react'
import { Typography } from '@material-ui/core'

// Simple helper to allow generic typography in React-admin's simple form iterators
// Basically we just ignore some input specific props (basePath, record), to avoid dom warnings
export default ({ basePath, record, ...rest }) => (
  <>
    <Typography {...rest} />
  </>
)
