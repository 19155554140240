import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import gql from 'graphql-tag'

import * as token from './token'

const URL = process.env.REACT_APP_AUTH_GRAPHQL_URL
const httpLink = createHttpLink({ uri: URL })

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})

const mutations = {
  login: gql`
    mutation($email: String, $password: String!) {
      login(email: $email, password: $password) {
        token
      }
    }
  `,
}

export default {
  login: async ({ username, password }) => {
    const response = await client.mutate({ mutation: mutations.login, variables: { email: username, password } })
    token.set(response.data.login.token)
  },
  logout: async () => token.remove(),
  checkAuth: () => {
    return token.get() != null ? Promise.resolve() : Promise.reject()
  },
  checkError: (error) => {
    const status = error.status
    if (
      status === 401 ||
      status === 403 ||
      (error.graphQLErrors?.length > 0 && error.graphQLErrors[0].extensions.code === 'UNAUTHENTICATED')
    ) {
      token.remove()
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getPermissions: () => (token.get() ? Promise.resolve('admin') : Promise.reject()),
}
