export const en = {
  ra: {
    action: {
      delete: 'Archive',
      restore: 'Restore',
    },
    notification: {
      deleted: 'Element archived |||| %{smart_count} elements archived',
    },
  },
  resources: {
    unArchivedSuccess: 'Entry un-archived',
    unArchivedFailed: 'Error: entry failed to un-archive',
    User: {
      name: 'User |||| Users',
      fields: {
        profile: {
          address: 'Address',
          industry: 'Industry',
          company: 'Company',
          preferredPronouns: 'Preferred pronouns',
          fontScale: 'Font scale',
        },
        deletedAt: 'Archived at',
      },
    },
    ProgramTopic: {
      name: 'Topic |||| Topics',
      fields: {
        deletedAt: 'Archived at',
      },
    },
    Program: {
      fields: {
        deletedAt: 'Archived at',
        preAssessment: {
          title: 'Title',
          type: 'Type',
          excerpt: 'Excerpt',
          introduction: 'Introduction',
          sections: 'Sections',
        },
        postAssessment: {
          title: 'Title',
          type: 'Type',
          excerpt: 'Excerpt',
          introduction: 'Introduction',
          sections: 'Sections',
        },
        programTopic: {
          id: 'Topic',
        },
        questions: '',
      },
    },
    Snippet: {
      fields: {
        question: {
          title: 'Question',
          rationale: 'Rationale',
          options: 'Options',
        },
        deletedAt: 'Archived at',
      },
    },
    MeasurementTag: {
      name: 'Measurement Tag |||| Measurement Tags',
      fields: {
        deletedAt: 'Archived at',
      },
    },
    AssessmentQuestion: {
      name: 'Assessment Question |||| Assessment Questions',
      fields: {
        deletedAt: 'Archived at',
      },
    },
  },
}

export const SUPPORTED_LOCALES = ['en', 'fr']

// Custom validator to only validate the english locale field in a TranslatableInput
export const enRequired = (value, allValues, input) => {
  const split = input.name.split('.')
  const inputLocale = split[split.length - 1]
  console.log(value, input.name)
  if (inputLocale === 'en' && !value) {
    return 'ra.validation.required'
  }
  return undefined
}

export const enValidate = [enRequired]
