import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloClient } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'

import buildGraphQLProvider from 'ra-data-graphql-simple'

import * as token from './auth/token'

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const apiUri = process.env.REACT_APP_GRAPHQL_URL

const httpLink = createHttpLink({ uri: apiUri })

const authLink = setContext(async (_, { headers: existingHeaders }) => {
  const headers = {
    ...existingHeaders,
    Accept: 'application/json',
  }

  if (token.get()) {
    headers.Authorization = `Bearer ${token.get()}`
  }

  return { headers }
})

const errorHandler = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // This method must return void or an Observable. If a Promise is returned
  // (like if we declare this to be async) it will expect it to resolve to
  // something Apollo-specific.

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const errorMessages = error.extensions.exception.errors?.map((subError) => capitalize(subError.message))
      error.message = errorMessages ? `${errorMessages?.join(', ')}.` : error.message
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`) // May need to handle these differently, just report them for now.
    networkError.message = networkError?.result.errors?.map((error) => error.message).join(', ') || networkError.message
  }
})

export const client = new ApolloClient({
  link: errorHandler.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
})

export default (options) =>
  buildGraphQLProvider({
    clientOptions: {
      uri: apiUri,
      link: errorHandler.concat(authLink).concat(httpLink),
    },
    ...options,
  })
