import React from 'react'
import Quill from 'quill'
import RaRichTextInput from 'ra-input-rich-text'
import htmlEditButton from 'quill-html-edit-button'
import insertTagButton from './insert-tag-button'

window.Quill = Quill
Quill.register('modules/htmlEditButton', htmlEditButton)
Quill.register('modules/insertTagButton', insertTagButton)
Quill.debug('error') // Silences annoying warnings: https://github.com/quilljs/quill/issues/2559

const toolbarOptions = [
  [{ header: [1, 2, 3, false] }],
  ['bold', 'italic', 'underline', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }],
  ['image'],
]
// TODO fix validation on RichTextInput
const RichTextInput = (props) => (
  <RaRichTextInput
    {...props}
    options={{
      modules: {
        htmlEditButton: {
          buttonTitle: 'Edit as HTML',
        },
        insertTagButton: [
          {
            label: "User's First Name",
            value: '{{firstName}}',
          },
          {
            label: "User's Last Name",
            value: '{{lastName}}',
          },
          {
            label: "User's Email",
            value: '{{email}}',
          },
          {
            label: 'First Snippet Link',
            value: '{{firstSnippetLink}}',
          },
          {
            label: 'Program URL',
            value: '{{programURL}}',
          },
          {
            label: 'Program Name',
            value: '{{programName}}',
          },
          {
            label: 'Program Pre-Assessment URL',
            value: '{{preAssessmentURL}}',
          },
          {
            label: 'Program Post-Assessment URL',
            value: '{{postAssessmentURL}}',
          },
          {
            label: 'Website URL',
            value: '{{webappUrl}}',
          },
        ],
        toolbar: toolbarOptions,
      },
    }}
  />
)

export default RichTextInput
