import React from 'react'
import { AppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import logo from '../../logo.png'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: '115px',
    margin: '5px 15px 5px 0',
  },
  appBar: {
    backgroundImage: 'linear-gradient(90deg, #fc2f52 0%, #f37021 100%);',
  },
})

export const LSAppBar = (props) => {
  const classes = useStyles()
  return (
    <AppBar className={classes.appBar} {...props}>
      <img className={classes.logo} src={logo} alt="Learning Snippets Logo" />
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <span className={classes.spacer} />
    </AppBar>
  )
}
