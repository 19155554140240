import React, { useState, useEffect } from 'react'

import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  NumberInput,
  SimpleForm,
  ImageField,
  BooleanField,
  BooleanInput,
  RichTextField,
  ReferenceField,
  ReferenceInput,
  ArrayInput,
  SelectInput,
  CheckboxGroupInput,
  DateField,
  TabbedForm,
  FormTab,
  EditButton,
  useListContext,
  SimpleFormIterator,
  TranslatableInputs,
  FormDataConsumer,
  TranslatableFields,
  ReferenceArrayInput,
  required,
  minValue,
  maxValue,
  useGetMatching,
} from 'react-admin'
import { TranslatableInput } from '../translatable-input'
import { useFormState } from 'react-final-form'
import RichTextInput from '../rich-text-input'
import { useQuery } from '@apollo/client'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MTextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

import { OrderableArrayFormIterator } from '../orderable-array'
import { OrderableSelect } from '../orderable-select'
import ArchiveOrRestoreButton from '../archive-or-restore-button'
import FormSafeTypography from '../utils/form-safe-typography'
import { client } from '../../buildGraphQLProvider'
import enrolledUsersQuery from './enrolled-users-query.js'

import { SUPPORTED_LOCALES } from '../../i18n'

import styles from './programs.module.css'

const EMAIL_TYPE_OPTIONS = [
  { id: 'WELCOME', name: 'WELCOME' },
  { id: 'CLOSING', name: 'CLOSING' },
  { id: 'NUDGE', name: 'NUDGE' },
]

const DEFAULT_EMAIL_CONTENT = '<p>Hello {{firstName}}</p><p><br></p><p>Warmly,</p><p>The Learning Snippets Team</p>'

const ProgramGrid = (props) => {
  const {
    filterValues: { isArchived },
  } = useListContext()

  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <ReferenceField source="programTopic.id" reference="ProgramTopic" label="Topic">
        <TextField source="title.en" />
      </ReferenceField>
      <ImageField source="icon" />
      <TextField source="name.en" label="Name" />
      <TextField source="description.en" label="Description" />
      <TextField source="slug" />
      <BooleanField source="trial" />
      <DateField source="createdAt" showTime />
      <DateField source={isArchived ? 'deletedAt' : 'updatedAt'} showTime />
      <EditButton />
      <ArchiveOrRestoreButton />
    </Datagrid>
  )
}

export const ProgramList = (props) => (
  <List
    {...props}
    filters={
      <Filter>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="isArchived" alwaysOn />
      </Filter>
    }
  >
    <ProgramGrid />
  </List>
)

export const ProgramEdit = (props) => {
  const url = props.location.pathname.split('/').filter((piece) => piece)
  const snippetTabIndex = '2'
  const [showWarning, setShowWarning] = useState(url.length === 3 && url[2] === snippetTabIndex)
  const [hasShownWarning, setHasShownWarning] = useState(false)
  const { loading, data } = useQuery(enrolledUsersQuery, { client, variables: { id: parseInt(props.id) } })

  if (loading) {
    return 'Loading...'
  }

  const { hasEnrolledUsers } = data

  const onOpenSnippetsTab = () => {
    if (!hasEnrolledUsers || hasShownWarning) return
    setShowWarning(true)
  }

  const onCloseWarning = () => {
    setShowWarning(false)
    setHasShownWarning(true)
  }

  const validatePercent = [
    minValue(0, 'Must be a non-decimal number between 0-100.'),
    maxValue(100, 'Must be a non-decimal number between 0-100.'),
  ]

  return (
    <Edit {...props}>
      <TabbedForm redirect={false}>
        <FormTab label="summary">
          <ReferenceInput source="programTopic.id" reference="ProgramTopic" fullWidth>
            <SelectInput optionText="title.en" />
          </ReferenceInput>
          <TextInput source="slug" fullWidth />
          <BooleanInput source="trial" fullWidth />
          <TextInput source="icon" fullWidth />
          <CheckboxGroupInput
            source="locales"
            fullWidth
            choices={[
              { id: 'en', name: 'English' },
              { id: 'fr', name: 'French' },
            ]}
            validate={required()}
          />
          <TranslatableInputs locales={SUPPORTED_LOCALES}>
            <TranslatableInput source="name" requiredLocales={['en']} fullWidth />
            <TextInput source="description" label="Description" multiline fullWidth />
            <RichTextInput source="signUpIntro" label="Sign Up Intro" fullWidth />
            <TextInput source="welcomeTitle" label="Welcome Page Title" fullWidth />
            <RichTextInput source="welcomeDescription" label="Welcome Page Content" fullWidth />
          </TranslatableInputs>
        </FormTab>
        <AssessmentTab type="pre" />
        <AssessmentTab type="post" />
        <FormTab label="snippets" onClick={onOpenSnippetsTab}>
          <Dialog
            onClose={onCloseWarning}
            aria-labelledby="warningModal"
            open={showWarning}
            className={styles.warningModal}
          >
            <DialogTitle id="warningModal">Users are registered in this program</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Adding or re-arranging snippets will impact users who are currently registered
              </DialogContentText>
              <DialogActions>
                <Button onClick={onCloseWarning} color="primary" autoFocus>
                  OK
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <ReferenceArrayInput source="snippetsIds" reference="Snippet" fullWidth>
            <OrderableSelect optionText="slug" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="emails">
          <ArrayInput source="emails">
            <SimpleFormIterator>
              <SelectInput source="type" label="Email Type" choices={EMAIL_TYPE_OPTIONS} />
              <TextInput source="fromEmail" label="From" fullWidth defaultValue="hello@learningsnippets.com" />
              <TextInput source="replyTo" label="Reply To" fullWidth defaultValue="hello@learningsnippets.com" />
              <FormDataConsumer>
                {({ formData, getSource }) => (
                  <TranslatableInputs locales={SUPPORTED_LOCALES}>
                    <TextInput source={getSource('subject')} label="Subject" fullWidth />
                    <RichTextInput
                      source={getSource('body')}
                      label="Body"
                      fullWidth
                      defaultValue={DEFAULT_EMAIL_CONTENT}
                    />
                  </TranslatableInputs>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="benchmarks">
          <FormSafeTypography variant="h6" gutterBottom>
            Overview Stats
          </FormSafeTypography>
          <NumberInput
            source="benchmark.preAssessmentsCompleted"
            label="Percentage of Pre-assessments Completed"
            validate={validatePercent}
            fullWidth
            helperText="(Integer values only, between 0-100)"
          />
          <NumberInput
            source="benchmark.snippetsStarted"
            label="Percentage of Snippets Started"
            validate={validatePercent}
            fullWidth
            helperText="(Integer values only, between 0-100)"
          />
          <NumberInput
            source="benchmark.snippetsCompleted"
            label="Percentage of Snippets Completed"
            validate={validatePercent}
            fullWidth
            helperText="(Integer values only, between 0-100)"
          />
          <NumberInput
            source="benchmark.postAssessmentsCompleted"
            label="Percentage of Post-assessments Completed"
            validate={validatePercent}
            fullWidth
            helperText="(Integer values only, between 0-100)"
          />
          <FormSafeTypography variant="h6" gutterBottom>
            Engagement
          </FormSafeTypography>
          <NumberInput
            source="benchmark.averageEngagementCompletion"
            label="Percentage of Average Engagement Completion"
            validate={validatePercent}
            fullWidth
            helperText="(Integer values only, between 0-100)"
          />
          <NumberInput
            source="benchmark.timesMultipleOptionsWereExplored"
            label="Number of Times Multiple Options Were Explored"
            fullWidth
          />
          <TextInput source="benchmark.averageTimeSpentOnSnippet" label="Average Time Spent On Snippet" fullWidth />
          <FormSafeTypography variant="h6" gutterBottom>
            Go Deeper
          </FormSafeTypography>
          <NumberInput source="benchmark.resourceClicks" label="Number of Times Resources Clicked" fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

const AssessmentTab = (props) => {
  // Type should be either 'pre' or 'post'
  const { type } = props
  const enabledSource = `${type}AssessmentEnabled`
  const formState = useFormState()
  const isEnabled = formState.values[enabledSource]

  if (!isEnabled)
    return (
      <FormTab label={`${type}-assessment`} {...props}>
        <BooleanInput
          source={enabledSource}
          helperText="Disabling will discard the assessment when saved, you will need to create it again from scratch"
          fullWidth
        />
      </FormTab>
    )

  return (
    <FormTab label={`${type}-assessment`} {...props}>
      <BooleanInput
        source={enabledSource}
        helperText="Disabling will discard the assessment when saved, you will need to create it again from scratch"
        fullWidth
      />
      <TranslatableInputs locales={SUPPORTED_LOCALES}>
        <TextInput source={`${type}Assessment.title`} fullWidth />
        <TextInput source={`${type}Assessment.excerpt`} multiline fullWidth />
        <RichTextInput source={`${type}Assessment.introduction`} />
      </TranslatableInputs>
      <FormSafeTypography variant="h6" gutterBottom>
        Sections
      </FormSafeTypography>
      <ArrayInput source={`${type}Assessment.sections`} fullWidth>
        <OrderableArrayFormIterator>
          <FormDataConsumer>
            {({ getSource }) => (
              <TranslatableInputs locales={SUPPORTED_LOCALES}>
                <TextInput source={getSource('title')} label="Title" fullWidth />
                <RichTextInput source={getSource('legend')} label="Legend" fullWidth />
              </TranslatableInputs>
            )}
          </FormDataConsumer>
          <BooleanInput
            source="randomize"
            label="Randomize"
            helperText="Enabling will randomize the order of all questions in this section"
            fullWidth
          />

          <FormSafeTypography style={{ marginTop: 16 }}>Questions</FormSafeTypography>
          <ReferenceArrayInput source="questionsIds" reference="AssessmentQuestion" fullWidth>
            <OrderableSelect optionText="title.en" AddItemForm={AddAssessmentQuestionForm} />
          </ReferenceArrayInput>
        </OrderableArrayFormIterator>
      </ArrayInput>
    </FormTab>
  )
}

const AddAssessmentQuestionForm = ({ values, addItem }) => {
  const [selected, setSelected] = useState(null)
  const [selectedTag, setSelectedTag] = useState(null)
  const {
    data: tagData,
    loading: tagLoading,
    error: tagError,
  } = useGetMatching('MeasurementTag', {}, { field: 'title', order: 'ASC' }, {}, 'tag_options', 'AssessmentQuestion')
  const {
    data: questionData,
    loading: questionLoading,
    error: questionError,
  } = useGetMatching(
    'AssessmentQuestion',
    {},
    { field: 'title', order: 'ASC' },
    selectedTag ? { tagId: selectedTag } : {},
    'question_options',
    'AssessmentQuestion'
  )

  useEffect(() => {
    if (!selected) return
    addItem(selected.id)
    setSelected(null)
  }, [selected, setSelected, addItem])

  if (tagError || questionError) return <p>Error fetching records</p>

  const tagOptions = tagData?.length > 0 ? tagData : []
  const questionOptions = questionData?.length > 0 ? questionData : []

  return (
    <>
      <Autocomplete
        options={tagOptions}
        onChange={(_e, value) => setSelectedTag(value?.id)}
        openOnFocus
        fullWidth
        loading={tagLoading}
        renderInput={(inputParams) => <MTextField variant="filled" {...inputParams} label="Tag" margin="normal" />}
        getOptionLabel={(option) => option.title}
        style={{ width: '250px', marginRight: '5px' }}
      />
      <Autocomplete
        options={questionOptions.filter((choice) => !values.includes(choice.id))}
        onChange={(_e, value) => setSelected(value)}
        openOnFocus
        fullWidth
        value={selected}
        loading={questionLoading}
        renderInput={(inputParams) => (
          <MTextField variant="filled" {...inputParams} label="Add Question" margin="normal" />
        )}
        getOptionLabel={(option) => option.title.en}
      />
    </>
  )
}

export const ProgramShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="programTopic.id" reference="ProgramTopic">
        <TextField source="title.en" />
      </ReferenceField>
      <TextField source="slug" />
      <BooleanField source="trial" />
      <ImageField source="icon" />
      <TextField source="name.en" />
      <TranslatableFields locales={SUPPORTED_LOCALES}>
        <TextField source="description" label="Description" />
        <TextField source="signUpIntro" label="Sign Up Intro" />
        <TextField source="welcomeTitle" label="Welcome Email Subject" />
        <RichTextField source="welcomeDescription" label="Welcome Email" />
      </TranslatableFields>
    </SimpleShowLayout>
  </Show>
)

export const ProgramCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="programTopic.id" reference="ProgramTopic" fullWidth>
        <SelectInput optionText="title.en" />
      </ReferenceInput>
      <TextInput source="slug" fullWidth />
      <BooleanInput source="trial" />
      <TextInput source="icon" fullWidth />
      <CheckboxGroupInput
        source="locales"
        fullWidth
        choices={[
          { id: 'en', name: 'English' },
          { id: 'fr', name: 'French' },
        ]}
        validate={required()}
      />
      <TranslatableInputs locales={SUPPORTED_LOCALES}>
        <TranslatableInput source="name" requiredLocales={['en']} fullWidth />
        <TextInput source="description" multiline fullWidth label="Description" />
        <TextInput source="signUpIntro" multiline fullWidth label="Sign Up Intro" />
        <TextInput source="welcomeTitle" fullWidth label="Welcome Page Title" />
        <RichTextInput source="welcomeDescription" multiline fullWidth label="Welcome Page Text" />
      </TranslatableInputs>
    </SimpleForm>
  </Create>
)
