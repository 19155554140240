import React from 'react'

import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  SimpleForm,
  EditButton,
  BooleanInput,
  useListContext,
  DateField,
  TranslatableInputs,
  TranslatableFields,
} from 'react-admin'

import ArchiveOrRestoreButton from '../archive-or-restore-button'
import { SUPPORTED_LOCALES } from '../../i18n'

const ProgramTopicGrid = (props) => {
  const {
    filterValues: { isArchived },
  } = useListContext()

  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="title.en" label="Title" />
      <DateField source={isArchived ? 'deletedAt' : 'updatedAt'} showTime />
      <EditButton />
      <ArchiveOrRestoreButton />
    </Datagrid>
  )
}

export const ProgramTopicList = (props) => (
  <List
    {...props}
    filters={
      <Filter>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="isArchived" alwaysOn />
      </Filter>
    }
  >
    <ProgramTopicGrid />
  </List>
)

export const ProgramTopicEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TranslatableInputs locales={SUPPORTED_LOCALES}>
        <TextInput source="title" fullWidth />
      </TranslatableInputs>
    </SimpleForm>
  </Edit>
)

export const ProgramTopicShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TranslatableFields locales={SUPPORTED_LOCALES}>
        <TextField source="title" />
      </TranslatableFields>
    </SimpleShowLayout>
  </Show>
)

export const ProgramTopicCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TranslatableInputs locales={SUPPORTED_LOCALES}>
        <TextInput source="title" fullWidth />
      </TranslatableInputs>
    </SimpleForm>
  </Create>
)
