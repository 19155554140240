import React from 'react'
import { Link } from 'react-router-dom'

import { SelectInput, Button, linkToRecord } from 'react-admin'

const SelectInputWithLinkToResource = ({ resourceType, ...props }) => {
  return (
    <>
      <SelectInput {...props} />
      {props.input.value && (
        <Button
          component={Link}
          to={linkToRecord(`/${resourceType}`, props.input.value)}
          label="Edit"
          target="_blank"
        />
      )}
    </>
  )
}

export default SelectInputWithLinkToResource
