import { useEffect, useReducer } from 'react'

import buildGraphQLProvider from './buildGraphQLProvider'

const dataProviderReducer = (state, { type, data }) => {
  switch (type) {
    case 'loaded':
      return {
        loading: false,
        dataProvider: data.dataProvider,
      }
    case 'authenticated':
      return {
        ...state,
        authenticated: true,
        dataProvider: data.dataProvider,
      }
    default:
      throw new Error(`Unrecognized action type "${type}"`)
  }
}

// Hook for loading the data provider ONLY when authenticated. Necessary until this is resolved: https://github.com/marmelab/react-admin/issues/4830
const useDataProvider = () => {
  const [{ loading, authenticated, dataProvider }, dispatch] = useReducer(dataProviderReducer, {
    loading: true,
    authenticated: false,
    dataProvider: null,
  })

  useEffect(() => {
    // Build initial blank provider to satisfy the admin requirement.
    // We need to wait to run the introspection query until we have an authorization token
    buildGraphQLProvider({
      introspection: {
        schema: {
          description: null,
          queryType: {
            name: 'Query',
          },
          mutationType: {
            name: 'Mutation',
          },
          subscriptionType: null,
          types: [],
          directives: [],
        },
      },
    })
      .then((dp) => dispatch({ type: 'loaded', data: { dataProvider: dp } }))
      .catch((error) => console.error(error))
  }, [])

  // Setter for using an authenticated dataProvider
  const authenticateDataProvider = async () => {
    if (authenticated) return

    // Now that we are authenticated, load the real data provider (which automatically runs an introspection query)
    const dp = await buildGraphQLProvider()
    dispatch({ type: 'authenticated', data: { dataProvider: dp } })
  }

  return [{ loading, dataProvider }, { authenticateDataProvider }]
}

export default useDataProvider