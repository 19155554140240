import React, { useState } from 'react'
import { Title } from 'react-admin'
import { useHistory, useParams, Redirect } from 'react-router-dom'
import { TextField, Button, Card, CardContent, CardActions, Snackbar } from '@material-ui/core'
import { ACCOUNT_ROLES } from '../../constants'
import { Alert, AlertTitle } from '@material-ui/lab'
import { client } from '../../buildGraphQLProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-light-svg-icons'
import { CSVReader } from 'react-papaparse'
import { useMutation } from '@apollo/client'
import uploadMutation from './account-user-bulk-upload-mutation'
import styles from './account-user-bulk-upload.module.css'
import CSVTemplate from './account-user-bulk-upload-template.csv'
import validateEmail from '../utils/validate-email'
import cookies from 'js-cookie'

import Typography from '@material-ui/core/Typography'

export const AccountUserBulkUpload = () => {
  const { account, role: roleParam } = useParams()
  let role
  switch (roleParam) {
    case 'owner':
      role = ACCOUNT_ROLES.OWNER
      break
    case 'leader':
      role = ACCOUNT_ROLES.LEADER
      break
    case 'learner':
    default:
      role = ACCOUNT_ROLES.LEARNER
      break
  }
  const [users, setUsers] = useState([])
  const [errors, setErrors] = useState({
    users: false,
  })
  const [submitError, setSubmitError] = useState(false)
  const [accountBulkUploadUsers] = useMutation(uploadMutation, { client })

  const handleSubmit = (e) => {
    e.preventDefault()
    const submitErrors = {
      users: !users.length,
    }
    setErrors(submitErrors)
    if (users.length) uploadUsers()
  }

  const history = useHistory()
  const uploadUsers = async () => {
    const variables = {
      account,
      role,
      usersJSON: JSON.stringify(users),
    }
    try {
      const { data: { accountBulkUploadUsers: uploadSuccess } = {} } = await accountBulkUploadUsers({ variables })
      if (uploadSuccess) history.push(`/Account/${account}/1`)
    } catch (error) {
      setSubmitError(true)
    }
  }
  // Validate each CSV entry
  const validateCSV = (data) => {
    setErrors({ ...errors, users: false })
    setUsers([])
    let csvError = false
    data.forEach((row) => {
      // Check for papaparse errors
      if (row.errors.length) {
        csvError = true
      } else {
        // Validate all required CSV fields
        const { data: rowData } = row
        if (!rowData?.firstName || !rowData?.lastName || !validateEmail(rowData?.email?.trim())) {
          console.error('Invalid value', rowData)
          csvError = true
        }
      }
    })
    // Set error state if errors, if not set users state for upload
    if (csvError) {
      setErrors({ ...errors, users: true })
    } else {
      setUsers(data)
    }
  }

  return (
    <div className="RaLayout-content-4">
      <Title title="Bulk Upload Users" />
      <div className="RaCreate-main-34 RaCreate-noActions-35">
        <form onSubmit={handleSubmit} redirect={false} className={styles.bulkUploadForm}>
          <Card>
            <CardContent>
              <CSVReader
                onDrop={(data) => validateCSV(data)}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={() => {
                  setErrors({ ...errors, users: false })
                  setUsers([])
                }}
                config={{ header: true, skipEmptyLines: true }}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>
              <Typography gutterBottom>
                <a href={CSVTemplate} download="bulk-upload-template.csv">
                  Download CSV Template
                </a>
              </Typography>
              <Typography style={{ marginBottom: 18 }} className="MuiFormLabel-root MuiInputLabel-shrink">
                Note: If you leave the password column blank, the users will be prompted to set a password when they
                confirm their email.
              </Typography>
              {errors.users && (
                <Typography color="error" style={{ marginBottom: 18 }}>
                  There was an error processing the uploaded CSV file. Check you are using the correct template format
                  and for any empty first names, last names, or invalid email addresses.
                </Typography>
              )}
              <Typography className="MuiFormLabel-root MuiInputLabel-shrink" style={{ marginTop: 9 }}>
                Activation email will be sent on the account start date. If the start date isn't set, it will send right
                away.
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" type="submit">
                <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon} />
                Upload
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
      <Snackbar
        open={submitError}
        autoHideDuration={3000}
        onClose={() => setSubmitError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={styles.alert}
      >
        <Alert severity="error" onClose={() => setSubmitError(false)}>
          <AlertTitle>Error</AlertTitle>
          There was an error creating the users. Check your CSV for already-used email addresses or missing columns and
          try again.
        </Alert>
      </Snackbar>
    </div>
  )
}
