import React, { useEffect, useState } from 'react'
import ArchiveOrRestoreButton from '../archive-or-restore-button'
import { TranslatableInput } from '../translatable-input'
import { useFormState } from 'react-final-form'
import { SUPPORTED_LOCALES } from '../../i18n'
import {
  TranslatableInputs,
  useDataProvider,
  TextInput,
  DateInput,
  DateTimeInput,
  NumberField,
  BooleanInput,
  DateField,
  EditButton,
  Datagrid,
  Filter,
  List,
  TextField,
  useListContext,
  required,
  NumberInput,
  Create,
  Edit,
  ReferenceInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  FormDataConsumer,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  useUpdate,
} from 'react-admin'
import { Typography, Button, Grid } from '@material-ui/core'
import { Box } from '../sanitized-mui'
import { VisibilityRounded } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { startOfDay, addDays } from 'date-fns'
import { OrderableSelect } from '../orderable-select'
import FormSafeTypography from '../utils/form-safe-typography'
import CadenceCalendar from '../cadence-calendar'
import styles from './accounts.module.css'
import { Cron } from 'react-js-cron'
import cronstrue from 'cronstrue'
import { dateParser, dateFormatter } from '../../utils/dateUtils'

import 'react-js-cron/dist/styles.css'

const AccountForm = (props) => {
  return (
    <TabbedForm redirect={false} {...props}>
      <FormTab label="Details">
        <TextInput source="name" label="Name" validate={[required()]} fullWidth />
        <NumberInput source="totalSeats" label="Seats" validate={[required()]} defaultValue={0} fullWidth />
        {props.mode === 'edit' && (
          <>
            {/* <Typography variant="h6" gutterBottom>
            Package
          </Typography>
          <ReferenceInput source="package.id" label="Title" reference="Package">
            <AutocompleteInputWithLinkToResource optionText="title.en" resourceType="Package" fullWidth />
          </ReferenceInput> */}
            <Typography variant="h6" gutterBottom>
              Programs
            </Typography>
            <ReferenceArrayInput source="programsIds" reference="Program" fullWidth>
              <OrderableSelect optionText="slug" />
            </ReferenceArrayInput>
            <Typography variant="h6" gutterBottom>
              Notes
            </Typography>
            <TranslatableInputs locales={SUPPORTED_LOCALES}>
              <TranslatableInput type="RichText" source="notes" label="Account Notes" fullWidth />
            </TranslatableInputs>
            <br />
            <Typography variant="h6" gutterBottom>
              User Confirmation Email
            </Typography>
            <TranslatableInputs locales={SUPPORTED_LOCALES}>
              <TranslatableInput
                type="RichText"
                source="userConfirmationEmail"
                label="User Confirmation Email"
                fullWidth
              />
            </TranslatableInputs>
          </>
        )}
        <Typography variant="h6" gutterBottom>
          Account End Date
        </Typography>
        <DateInput
          source="endDate"
          parse={(date) => {
            return date ? addDays(startOfDay(new Date(date)), 1) : null
          }}
        />
      </FormTab>
      {props.children}
    </TabbedForm>
  )
}

export const AccountCreate = (props) => (
  <Create {...props}>
    <AccountForm />
  </Create>
)

const CronInput = ({ record }) => {
  const [cron, setCron] = useState(record.cadenceCron)
  const [update, { loading, error }] = useUpdate()

  const setCadenceCron = (cron) => {
    update('Account', record.id, { ...record, cadenceCron: cron }, record, {
      onSuccess: ({ data }) => {
        setCron(data.cadenceCron)
      },
    })
  }
  useEffect(() => {
    if (cron !== record.cadenceCron) {
      setCadenceCron(cron)
    }
  }, [cron])
  return (
    <>
      <Cron value={cron} disabled={loading} setValue={setCron} clockFormat="12-hour-clock" />
      {!loading && cron && <>SET FOR: {cronstrue.toString(cron, { verbose: true })} EST</>}
    </>
  )
}

export const AccountEdit = (props) => {
  const [calendarShown, setCalendarShown] = useState(false)
  const { basePath, id } = props
  return (
    <Edit {...props} undoable={false}>
      <AccountForm mode="edit">
        <FormTab label="Users">
          <Typography variant="h6" gutterBottom>
            Learners
            <Button
              href={`/#${basePath}/${id}/bulk-upload/learner`}
              color="primary"
              size="small"
              style={{ marginLeft: '1rem' }}
            >
              <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon} />
              Bulk Upload
            </Button>
          </Typography>
          <ReferenceArrayInput label="Users" reference="User" source="learnersIds" fullWidth>
            <AutocompleteArrayInput autocomplete="off" optionText="email" />
          </ReferenceArrayInput>
          <Typography variant="h6" gutterBottom>
            Leaders
            <Button
              href={`/#${basePath}/${id}/bulk-upload/leader`}
              color="primary"
              size="small"
              style={{ marginLeft: '1rem' }}
            >
              <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon} />
              Bulk Upload
            </Button>
          </Typography>
          <ReferenceArrayInput label="Users" reference="User" source="leadersIds" fullWidth>
            <AutocompleteArrayInput autocomplete="off" optionText="email" />
          </ReferenceArrayInput>
          <Typography variant="h6" gutterBottom>
            Owners
            <Button
              href={`/#${basePath}/${id}/bulk-upload/owner`}
              color="primary"
              size="small"
              style={{ marginLeft: '1rem' }}
            >
              <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon} />
              Bulk Upload
            </Button>
          </Typography>
          <ReferenceArrayInput label="Users" reference="User" source="ownersIds" fullWidth>
            <AutocompleteArrayInput autocomplete="off" optionText="email" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="Program Notes">
          <ProgramNotes />
        </FormTab>
        <FormTab label="Schedule">
          <DateTimeInput label="Start Date(EST)" source="startDate" />
          <br />
          <FormDataConsumer>
            {({ formData, record, ...rest }) => {
              return <CronInput record={record} />
            }}
          </FormDataConsumer>
          <TextInput source="cadenceCron" disabled label="Schedule (Accepts Cron format)" />
          <FormDataConsumer>
            {({ formData: { cadenceCron } }) =>
              cadenceCron ? (
                <p>
                  Test here:
                  <a
                    href={`https://crontab.guru/#${cadenceCron}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{`https://crontab.guru/#${cadenceCron}`}</a>
                </p>
              ) : null
            }
          </FormDataConsumer>
          <Box p="1em" sx={{ width: '100% !important', padding: 0 }}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <ArrayInput label="Skip Dates" source="blacklistedDays">
                  <SimpleFormIterator>
                    <DateInput label="Date" source="date" parse={dateParser} format={dateFormatter} />
                  </SimpleFormIterator>
                </ArrayInput>
              </Grid>
              <Grid item xs={6}>
                <FormSafeTypography variant="h6" gutterBottom>
                  Schedule Preview
                </FormSafeTypography>
                <FormDataConsumer>
                  {({ formData: { blacklistedDays, cadenceCron, startDate, endDate } }) => {
                    return calendarShown ? (
                      <CadenceCalendar
                        cron={cadenceCron}
                        packageStartDate={startDate}
                        blacklistedDays={blacklistedDays.map((date) => date?.date)}
                        endDate={endDate}
                      />
                    ) : (
                      <Button onClick={() => setCalendarShown(true)}>
                        <VisibilityRounded />
                        Show Preview
                      </Button>
                    )
                  }}
                </FormDataConsumer>
              </Grid>
            </Grid>
          </Box>
        </FormTab>
      </AccountForm>
    </Edit>
  )
}

const ProgramNotes = () => {
  const { values: { programsIds = [] } = {} } = useFormState()
  const [{ data: programs, loading, errors }, setState] = useState({ data: [], loading: false, errors: [] })
  const dataProvider = useDataProvider()

  const getPrograms = async () => {
    try {
      const { data } = await dataProvider.getMany('Program', { ids: programsIds })
      setState({ data, loading: false, errors })
    } catch (error) {
      setState({ data: programs, loading, errors: [...errors, error] })
    }
  }

  useEffect(() => {
    if (programsIds.length > 0) {
      setState({ data: programs, loading: true, errors })
      getPrograms()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [programsIds])

  return programs.map((program) => (
    <>
      <Typography variant="h6" gutterBottom>
        {program.name.en}
      </Typography>
      <TranslatableInputs locales={SUPPORTED_LOCALES}>
        <TranslatableInput type="RichText" source={`programNotes.${program.slug}`} label="Program Note" fullWidth />
      </TranslatableInputs>
    </>
  ))
}

export const AccountList = (props) => (
  <List
    {...props}
    filters={
      <Filter>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="isArchived" alwaysOn />
      </Filter>
    }
  >
    <AccountGrid />
  </List>
)

const AccountGrid = (props) => {
  const {
    filterValues: { isArchived },
  } = useListContext()
  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="name" label="Name" />
      <NumberField source="totalSeats" label="Seats" />
      <DateField source="createdAt" showTime />
      <DateField source={isArchived ? 'deletedAt' : 'updatedAt'} showTime />
      <DateField source="endDate" />
      {!isArchived && <EditButton />}
      <ArchiveOrRestoreButton />
    </Datagrid>
  )
}
