import React from 'react'

import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  SimpleForm,
  EditButton,
  BooleanInput,
  useListContext,
  DateField,
} from 'react-admin'

import ArchiveOrRestoreButton from '../archive-or-restore-button'

const MeasurementTagGrid = (props) => {
  const {
    filterValues: { isArchived },
  } = useListContext()

  return (
    <Datagrid {...props}>
      <TextField source="id" />
      <TextField source="title" />
      <DateField source={isArchived ? 'deletedAt' : 'updatedAt'} showTime />
      <EditButton />
      <ArchiveOrRestoreButton />
    </Datagrid>
  )
}

export const MeasurementTagList = (props) => (
  <List
    {...props}
    filters={
      <Filter>
        <TextInput label="Search" source="q" alwaysOn />
        <BooleanInput label="Archived" source="isArchived" alwaysOn />
      </Filter>
    }
  >
    <MeasurementTagGrid />
  </List>
)

export const MeasurementTagEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" fullWidth />
    </SimpleForm>
  </Edit>
)

export const MeasurementTagShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
    </SimpleShowLayout>
  </Show>
)

export const MeasurementTagCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" fullWidth />
    </SimpleForm>
  </Create>
)
