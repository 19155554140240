// Copied a starting point from here: https://github.com/marmelab/react-admin/pull/1836
// Some updates were required for react-admin upgrades since then.

import React, { Component, Children, cloneElement } from 'react'
import compose from 'compose-function'
import { FormInput, translate } from 'react-admin'
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import MoveIcon from '@material-ui/icons/Reorder'
import AddIcon from '@material-ui/icons/AddCircleOutline'

const styles = (theme) => ({
  line: {
    display: 'flex',
    listStyleType: 'none',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: { display: 'block' },
    '&.fade-enter': {
      opacity: 0.01,
      transform: 'translateX(100vw)',
    },
    '&.fade-enter-active': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'all 200ms ease-in',
    },
    '&.fade-exit': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '&.fade-exit-active': {
      opacity: 0.01,
      transform: 'translateX(100vw)',
      transition: 'all 200ms ease-in',
    },
  },
  index: {
    width: '3em',
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  form: { flex: 2 },
  action: {
    paddingTop: '0.5em',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
})

const DraggableFormInput = ({
  basePath,
  children,
  classes = {},
  id,
  index,
  member,
  onRemove,
  record,
  resource,
  translate,
}) => (
  <Draggable draggableId={id} index={index}>
    {(provided) => (
      <li className={classes.line} ref={provided.innerRef} {...provided.draggableProps}>
        <div {...provided.dragHandleProps}>
          <MoveIcon className={classes.index} />
        </div>
        <section className={classes.form}>
          {Children.map(children, (input) => (
            <FormInput
              index={index}
              basePath={basePath}
              input={cloneElement(input, {
                source: input.props.source ? `${member}.${input.props.source}` : member,
                label: input.props.label || input.props.source,
              })}
              record={record}
              resource={resource}
            />
          ))}
        </section>
        <span className={classes.action}>
          <button size="small" onClick={onRemove(index)}>
            <CloseIcon className={classes.leftIcon} />
            {translate('ra.action.remove')}
          </button>
        </span>
      </li>
    )}
  </Draggable>
)

const OrderableArrayInput = compose(translate, withStyles(styles))(DraggableFormInput)

const sanitizeProps = ({ classes, ...props }) => props

const formStyles = (theme) => ({
  root: {
    padding: 0,
    marginBottom: 0,
    '& > li:last-child': {
      borderBottom: 'none',
    },
  },
  line: {
    display: 'flex',
    listStyleType: 'none',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: { display: 'block' },
    '&.fade-enter': {
      opacity: 0.01,
      transform: 'translateX(100vw)',
    },
    '&.fade-enter-active': {
      opacity: 1,
      transform: 'translateX(0)',
      transition: 'all 200ms ease-in',
    },
    '&.fade-exit': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '&.fade-exit-active': {
      opacity: 0.01,
      transform: 'translateX(100vw)',
      transition: 'all 200ms ease-in',
    },
  },
  form: { flex: 2 },
  action: {
    paddingTop: '0.5em',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
})

class OrderedFormIterator extends Component {
  constructor(props) {
    super(props)
    // we need a unique id for each field for a proper enter/exit animation
    // but redux-form doesn't provide one (cf https://github.com/erikras/redux-form/issues/2735)
    // so we keep an internal map between the field position and the react-admin name of the field
    this.ids = props.fields ? props.fields.map((member) => member) : []
  }

  removeField = (index) => () => {
    const { fields } = this.props
    this.ids.splice(index, 1)
    fields.remove(index)
  }

  addField = () => {
    const { fields } = this.props
    fields.push({})
    this.ids.push(`${fields.name}[${fields.length}]`)
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const { fields } = this.props
    const startIndex = result.source.index
    const endIndex = result.destination.index
    const [removed] = this.ids.splice(startIndex, 1)
    this.ids.splice(endIndex, 0, removed)
    fields.move(startIndex, endIndex)
  }

  render() {
    const { classes = {}, fields, translate } = this.props
    return fields ? (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ul className={classes.root} ref={provided.innerRef}>
              <TransitionGroup component={null}>
                {fields.map((member, index) => (
                  <CSSTransition key={member} timeout={0} classNames="fade">
                    <OrderableArrayInput
                      id={member}
                      index={index}
                      member={member}
                      onRemove={this.removeField}
                      {...sanitizeProps(this.props)}
                    />
                  </CSSTransition>
                ))}
              </TransitionGroup>
              {provided.placeholder}
              <li className={classes.line}>
                <span className={classes.action}>
                  <Button size="small" onClick={this.addField}>
                    <AddIcon className={classes.leftIcon} />
                    {translate('ra.action.add')}
                  </Button>
                </span>
              </li>
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    ) : null
  }
}

export const OrderableArrayFormIterator = compose(translate, withStyles(formStyles))(OrderedFormIterator)
