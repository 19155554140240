import React from 'react'
import { Admin, Resource, Layout } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { Route } from 'react-router-dom'
import englishMessages from 'ra-language-english'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressBook,
  faLightbulbOn,
  faBooks,
  faTags,
  faCut,
  faBoxFull,
  faFileChartLine,
  faTasks,
  faFileSignature,
} from '@fortawesome/pro-light-svg-icons'
import merge from 'lodash/merge'

import authProvider from './auth/provider'
import useDataProvider from './useDataProvider'

import { UserList, UserCreate, UserEdit, UserShow } from './components/users'
import { AccountUserBulkUpload } from './components/account-user-bulk-upload'
import { BulkEmail } from './components/bulk-emails'
import { ProgramTopicList, ProgramTopicCreate, ProgramTopicEdit, ProgramTopicShow } from './components/program-topics'
import { PackageList, PackageEdit, PackageShow, PackageCreate } from './components/package'
import {
  MeasurementTagList,
  MeasurementTagCreate,
  MeasurementTagEdit,
  MeasurementTagShow,
} from './components/measurement-tags'
import { ProgramList, ProgramCreate, ProgramEdit, ProgramShow } from './components/programs'
import {
  AssessmentQuestionList,
  AssessmentQuestionCreate,
  AssessmentQuestionEdit,
} from './components/assessment-questions'
import { ReportList } from './components/reports'
import { AccountCreate, AccountList, AccountEdit } from './components/accounts'
import { SnippetList, SnippetCreate, SnippetEdit } from './components/snippets'
import { LSAppBar } from './components/app-bar'
import * as domainMessages from './i18n'

const messages = {
  // fr: { ...frenchMessages, ...domainMessages.fr },
  en: merge(englishMessages, domainMessages.en),
}
const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === 'fr' ? messages.en : messages.en),
  'en' // Default locale
)

const CustomLayout = (props) => <Layout {...props} appBar={LSAppBar} />

const loadData =
  ({ authenticateDataProvider }) =>
    async (permissions) => {
      if (!permissions) return []

      await authenticateDataProvider()

      return [
        <Resource
          name="User"
          list={UserList}
          edit={UserEdit}
          show={UserShow}
          create={UserCreate}
          icon={() => <FontAwesomeIcon icon={faAddressBook} />}
        />,
        <Resource
          name="ProgramTopic"
          list={ProgramTopicList}
          edit={ProgramTopicEdit}
          show={ProgramTopicShow}
          create={ProgramTopicCreate}
          icon={() => <FontAwesomeIcon icon={faLightbulbOn} />}
        />,
        <Resource
          name="Program"
          list={ProgramList}
          edit={ProgramEdit}
          show={ProgramShow}
          create={ProgramCreate}
          icon={() => <FontAwesomeIcon icon={faBooks} />}
        />,
        <Resource
          name="AssessmentQuestion"
          list={AssessmentQuestionList}
          edit={AssessmentQuestionEdit}
          create={AssessmentQuestionCreate}
          icon={() => <FontAwesomeIcon icon={faTasks} />}
        />,
        <Resource
          name="Snippet"
          list={SnippetList}
          edit={SnippetEdit}
          create={SnippetCreate}
          icon={() => <FontAwesomeIcon icon={faCut} />}
        />,
        <Resource
          name="MeasurementTag"
          list={MeasurementTagList}
          edit={MeasurementTagEdit}
          show={MeasurementTagShow}
          create={MeasurementTagCreate}
          icon={() => <FontAwesomeIcon icon={faTags} />}
        />,
        // <Resource
        //   name="Package"
        //   list={PackageList}
        //   edit={PackageEdit}
        //   show={PackageShow}
        //   create={PackageCreate}
        //   icon={() => <FontAwesomeIcon icon={faBoxFull} />}
        // />,
        <Resource
          name="Account"
          edit={AccountEdit}
          list={AccountList}
          icon={() => <FontAwesomeIcon icon={faFileSignature} />}
          create={AccountCreate}
        />,
        <Resource name="Report" list={ReportList} icon={() => <FontAwesomeIcon icon={faFileChartLine} />} />,
      ]
    }

const App = () => {
  const [{ loading, dataProvider }, { authenticateDataProvider }] = useDataProvider()

  if (loading) {
    return <div>Loading</div>
  }

  return (
    <Admin
      layout={CustomLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      customRoutes={[
        <Route exact path="/Account/:account/bulk-upload/:role" component={AccountUserBulkUpload} />,
        <Route exact path="/User/bulk-email" component={BulkEmail} />,
      ]}
    >
      {loadData({
        authenticateDataProvider,
      })}
    </Admin>
  )
}

export default App
