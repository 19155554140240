import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { useQuery } from '@apollo/client'
import { isSameDay, addDays, endOfMonth } from 'date-fns'
import { CircularProgress } from '@material-ui/core'
import calculateSchedule from './calculate-schedule-query'
import 'react-calendar/dist/Calendar.css'
import { client } from '../../buildGraphQLProvider'
import styles from './cadence-calendar.module.css'

const CadenceCalendar = ({ cron = "0 * * * 0-6", blacklistedDays, packageStartDate, endDate }) => {
  const [activeDate, setActiveDate] = useState(new Date())
  const { loading: scheduleLoading, data: scheduleData } = useQuery(calculateSchedule, {
    client,
    variables: {
      cron,
      blacklistedDays,
      packageStartDate,
      startDate: activeDate,
      endDate: endDate || addDays(endOfMonth(activeDate), 7),
    },
  })

  if (scheduleLoading) return <CircularProgress />

  const onMonthChange = ({ activeStartDate }) => {
    setActiveDate(activeStartDate)
  }

  const { calculateSchedule: calculatedDays } = scheduleData
  const scheduledDays = calculatedDays.map((day) => new Date(day))

  return (
    <Calendar
      value={activeDate}
      onActiveStartDateChange={onMonthChange}
      tileClassName={({ date }) => {
        if (scheduledDays.find((day) => isSameDay(day, date))) return styles.activeDate
      }}
      tileDisabled={({ view }) => view === 'month'}
      calendarType="US"
    />
  )
}
export default CadenceCalendar
