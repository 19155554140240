import React from 'react'
import { TextInput } from 'react-admin'
import RichTextInput from '../rich-text-input'

const localeRequired =
  (locales = [], currentLocale, message = 'Missing value for locale: ') =>
  (value) => {
    if (!currentLocale) return
    if (!locales.includes(currentLocale)) return
    let invalid
    locales.forEach((locale) => {
      if (!value) {
        invalid = message.concat(`${locale}, `)
      }
    })
    return invalid
  }

const sourceLocale = (source = '') => {
  const localeRegex = '[^.]+$'
  return source.match(localeRegex)[0]
}

export const TranslatableInput = ({ type, requiredLocales = [], ...props }) => {
  let validate = props.validate || []
  if (typeof validate === 'function') {
    validate = [validate]
  }
  const locale = sourceLocale(props.source)

  const renderInput = (type) => {
    switch (type) {
      case 'RichText':
        return <RichTextInput {...props} validate={[...validate, localeRequired(requiredLocales, locale)]} />
      case 'Text':
      default:
        return <TextInput {...props} validate={[...validate, localeRequired(requiredLocales, locale)]} />
    }
  }
  return renderInput(type)
}
